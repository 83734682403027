<template>
  <div class="userLogin">
    <div class="loginBox">
      <div class="loginFrom">
        <!-- logo图片 -->
        <!-- <img src="../../assets/img/credis-logo.png"
             alt=""> -->
        <div class="fromBox">
          <h1>Login</h1>
          <p>Sign In to your buyer account</p>
          <!-- 登录表单 -->
          <el-form :model="loginForm"
                   :rules="loginRules"
                   ref="loginForm"
                   class="demo-ruleForm">
            <!-- 邮箱 -->
            <el-form-item prop="loginEmail">
              <el-input placeholder="Email"
                        suffix-icon="el-icon-message"
                        v-model="loginForm.loginEmail">
              </el-input>
            </el-form-item>
            <!-- 密码 -->
            <el-form-item prop="loginPassword">
              <el-input type="loginPassword"
                        placeholder="password"
                        v-model="loginForm.loginPassword"
                        show-password></el-input>
            </el-form-item>
            <el-form-item>
              <!-- 忘记密码 -->
              <el-link type="primary"
                       :underline="false"
                       @click="$router.push('/F_reset')">Forgot password?</el-link>
              <!-- 登陆状态 -->
              <el-checkbox v-model="loginChecked">Remember Me</el-checkbox>
              <!-- 登录按钮 -->
              <el-button type="primary"
                        :loading="$store.state.loadingBool"
                         @click="loginTest('loginForm')">Login</el-button>
            </el-form-item>
          </el-form>
          <!-- 忘记密码 -->
          <p class="reg">Not a member?<el-link :underline="false"
                     type="primary"
                     @click="$router.push('/F_register')">Register Now!</el-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入接口
import {
  // userQuery, // 查询个人资料
  userLogin // 用户登录
} from '../../api/buyerFront'
// 引入token
import {
  getToken,
  setToken // 创建token
} from '../../utils/token'
export default {
  data () {
    // 登录界面邮箱验证
    var email = (rule, value, callback) => {
      // 邮箱正则
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
      // 邮箱框value判断
      if (!value) {
        callback(new Error('Mailbox cannot be empty'))
      } else if (!reg.test(value)) {
        callback(new Error('Please input correct email format'))
      } else {
        callback()
      }
    }
    // 登录界面密码验证
    var password = (rule, value, callback) => {
      // 密码框value值判断
      if (!value) {
        return callback(new Error('Password cannot be empty'))
      } else {
        callback()
      }
    }
    return {
      // 登录状态复选框
      loginChecked: false,
      // 登录表单
      loginForm: {
        loginEmail: '', // 邮箱
        loginPassword: '', // 密码
        loginRole: 'RULE_BUYER' // 用户身份
      },
      // 表单验证规则
      loginRules: {
        // 邮箱验证
        loginEmail: [
          { validator: email, trigger: 'blur' }
        ],
        // 密码验证
        loginPassword: [
          { validator: password, trigger: 'blur' }
        ]
      },
      // 加载
      loadingBool: false
    }
  },
  created () {
    this.$store.commit('getLoading', false)
    const that = this
    // 当前页面监视键盘输入
    document.onkeydown = function (e) {
      const e1 =
        e || event || window.event
      if (e1.keyCode === 13) {
        that.loginTest('loginForm')
      }
    }
  },
  methods: {
    // 登录
    loginTest (formName) {
      const that = this
      // element自动的表单验证
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 验证通过 执行登录接口
          this.$store.commit('getLoading', true)
          that.$store.state.loadingBool = true
          userLogin({
            mail: this.loginForm.loginEmail, // 邮箱
            password: this.loginForm.loginPassword, // 密码
            role: this.loginForm.loginRole, // 用户登录身份
            status: this.loginChecked ? 1 : 0 // 登录状态
          }).then(result => {
            that.$store.state.loadingBool = false
            // 接口调用成功 状态码且为200
            if (result.data.code === 200) {
              setToken(result.data.data.token) // 创建token
              if (window.history.length <= 2) {
                this.$router.push('/F_home')
              } else {
                this.$router.go(-1)
              }
              localStorage.setItem('buyer-userName', result.data.data.firstname)
              localStorage.setItem('buyer-userId', result.data.data.id)
              localStorage.setItem('buyer-site', result.data.data.country)
              localStorage.setItem('buyer-tokenTime', result.data.data.tokeTime)
              this.$store.commit('getLoginbool', true)
              this.$message.success('Login successful') // 弹框提示
              this.$store.commit('getLoading', false)
            } else {
              // 接口调用失败 货状态码不为200
              this.$message.error('The account number does not exist or the password is wrong') // 弹框提示
            }
          }).catch(err => {
            return err // 接口调用错误提示
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.userLogin {
  min-height: 600px;
  .loginBox {
    background-color: white;
    min-height: 550px;
    position: relative;
    .loginFrom {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 400px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      img {
        display: block;
        width: 140px;
        height: auto;
      }
      .fromBox {
        width: 100%;
        border: 1px solid #eeeeee;
        h1 {
          background-color: #f8ac59;
          font-size: 24px;
          font-weight: normal;
          line-height: 30px;
          color: white;
          padding: 15px 0;
        }
        p {
          font-size: 16px;
          line-height: 24px;
          color: #6c757d;
          text-align: left;
          padding-left: 25px;
          margin-top: 25px;
        }
        .el-form {
          padding: 0 25px;
          .el-form-item {
            margin: 20px 0 0 0;
            &:nth-child(3) {
              margin: 0;
            }
          }
          .el-checkbox {
            margin-top: 10px;
            float: left;
          }
          .el-link {
            margin-top: 10px;
            float: right;
          }
          .el-button {
            width: 100%;
            background-color: #f8ac59;
            border-color: #f8ac59;
          }
        }
        .reg {
          margin-top: 0;
          line-height: 30px;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
        }
      }
    }
  }
  // background: red;
}
</style>
